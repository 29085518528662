.warning_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}
.confirmDialog_content {
  color: black !important;
  font-size: large;
}
