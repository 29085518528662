@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Signika:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}
.container {
  padding: 1% 8%;
}
.fa-brands {
  background-color: white;
  color: #06c8f7;
  border-radius: 80%;
  padding: 10px;
  margin-right: 10px;
  font-size: 20px;
}
.fa-facebook-f {
  padding-left: 13px;
  padding-right: 13px;
}
.heading {
  color: #262626;
  font-family: Poppins;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.cartBox {
  width: 320px;
  height: 360px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.imageBox {
  width: 320px; /* Adjust the width of the container as needed */
  height: 180px; /* Adjust the height of the container as needed */
  overflow: hidden;
  padding-top: 10px;
}
.fit-image {
  max-width: 100%; /* Make sure the image does not exceed the container width */
  max-height: 100%; /* Make sure the image does not exceed the container height */
  display: block; /* Remove any extra spacing or margin around the image */
  margin: 0 auto;
  border-radius: 4%;
}
.visitor {
  border-radius: 10px;
  background: #fff;
  width: 210px;
  height: 34px;
  flex-shrink: 0;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 15px;
  position: relative;
  top: -18px;
  left: 50px;
}
.subHeading {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.5px; /* 75.714% */
  letter-spacing: 1.8px;
}
.backgroungBlue {
  padding: 2px;
}
div.slick-arrow.slick-prev::before,
div.slick-arrow.slick-next::before {
  color: #000;
}
div.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.para {
  color: #3e3e3e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 146.154% */
  letter-spacing: 1px;
  padding: 10px 0;
  width: 100%;
  margin-bottom: 10px; /* Add space below each paragraph */
  display: block;
}
@media (max-width: 450px) {
  .hide-on-phone {
    display: none;
  }
  .heading {
    font-size: 20px;
    align-items: center;
    text-align: center;
  }
  .subHeading {
    font-size: 18px;
  }
  .para {
    font-size: 12px;
  }
  .button {
    display: flex;
    justify-content: center;
  }
}
.headerImg {
  position: relative;
  top: -45px;
}
.header-image {
  position: relative;
  max-height: 300px;
  width: 100%;
}

@media (max-width: 1090px) {
  .subHeading {
    font-size: 20px;
  }
  .para {
    font-size: 14px;
  }
}
.flex {
  display: flex;
  justify-content: space-between;
}
