/* .varcode_main_div {
  flex: 1;
  flex-direction: row;
  background-color: #31e3ae;
  height: auto;
  width: auto;
}
.Varcode_inner_div1 {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: auto;
}
.Varcode_inner_div2 {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: auto;
  display: flex;
}
.varcode_man_image {
  height: 320px;
  width: auto;
}

.varcode_image {
  height: 350px;
  width: auto;
} */
/* /////////// */
.airoplan {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.airoplan img {
  width: 100%;
  height: auto;
  max-width: 100px;
}
/* .varCode_up {
  height: 25vh;
  padding: 80px;
  width: 700px;
}

.varCode_up h6,
.varCode_left_2 h6 {
  font-size: 25px;
  font-weight: 600;
}
.varCode_up p {
  font-size: 14px;
  padding-right: auto;
  font-weight: 400;
  color: #4e5654;
}
.varCode_left_2 p {
  font-size: 14px;
  font-weight: 400;
  color: #4e5654;
}

.varCode_up button {
  height: 40px;
  width: 170px;
  border-radius: 7px;
  border: transparent;
  color: #0dc6f4;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  margin: 20px 0px;
}
.varCode_left_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 80px;
}

.varCode_left_2 img {
  height: 30px;
  padding: 15px;
}
.varcode_mobile_inner h6,
.varcode_mobile_inner p,
.varcode_mobile_inner button {
  display: none;
}

@media (max-width: 1052px) {
  .varcode_man_image {
    height: 320px;
    width: 400px;
  }
}
@media (max-width: 992px) {
  .varcode_main_div {
    height: auto;
    width: auto;
  }
  .varCode_up p {
    font-size: 12px;
    font-weight: 400;
    color: #4e5654;
  }
  .varCode_up button {
    height: 30px;
    width: 120px;
    border-radius: 7px;
    border: transparent;
    color: #0dc6f4;
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
    margin: 5px 0px;
  }
} */
/* @media (max-width: 767px) {
  .varcode_main_div {
    display: none;
  }
  .varcode_mobile_main {
    background-image: url(../Images/barCodeMob.jpeg);
    background-size: 100% 100%;
    object-fit: contain;
    max-width: auto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    height: auto;
  }
  .varcode_mobile_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 50%;
    margin: 20px;
    height: 35vh;
  }
  .varcode_mobile_inner h6 {
    display: block;
    font-size: 20px;
    font-weight: 700;
  }
  .varcode_mobile_inner p {
    display: block;

    padding-top: 10px;
    font-size: 8px;
    font-weight: 700;
    color: #4e5654;
  }
  .varcode_mobile_inner button {
    display: block;
    height: 25px;
    width: 100px;
    border: transparent;
    border-radius: 5px;
    color: #0dc6f4;
    font-size: 8px;
    font-weight: 700;
    margin-top: 10px;
  }
  .airoplan {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
    margin-top: 20px;
  }
  .airoplan img {
    height: 70px;
  }
} */
/* @media (max-width: 767px) {
  .varcode_main_div {
    display: none;
  }

  .varcode_mobile_main {
    display: block;
    background-image: url(../Images/barCodeMob.jpeg);
    background-size: 100% 100%;
    display: flex;
    text-align: center;

    justify-content: flex-end;
    object-fit: contain;
    height: auto;
  }
  .varcode_mobile_inner {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    max-width: 40%;
    padding: 60px;
    height: auto;
  }

  .varcode_mobile_inner h6 {
    display: block;
    font-size: 15px;
    font-weight: 600;
  }
  .varcode_mobile_inner p {
    display: block;
    margin-top: 10px;
    font-size: 8px;
    font-weight: 700;
    color: #4e5654;
  }
  .varcode_mobile_inner button {
    display: block;
    height: 25px;
    width: 100px;
    border: transparent;
    border-radius: 5px;
    color: #0dc6f4;
    font-size: 8px;
    font-weight: 700;
    margin-top: 5px;
  }
  .airoplan {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
    margin-top: 20px;
  }
  .airoplan img {
    height: 70px;
  }
} */
.barcode_container {
  background-color: #31e3ae;
  height: 31.25rem;
  width: auto;
}
.barcode_man {
  height: 20%;
  width: 35%;
  position: relative;
  top: -78px;
}
.were-hiring {
  padding-left: 5rem;
  padding-top: 4rem;
}
.hiringPara {
  color: #3e3e3e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 146.154% */
  letter-spacing: 1px;
  padding: 10px 0px;
  width: 85%;
  margin-bottom: 10px; /* Add space below each paragraph */
  display: block;
}
.test {
  display: flex;
}
.test2 {
  display: flex;
  justify-content: center;
  width: 60%;
  padding-top: 26px;
}
.register-btn {
  cursor: pointer;
  width: 154px;
  height: 40px;
  border-radius: 8px;
  color: #06c8f7;
  font-weight: 600;
  border-style: none;
  font-size: 14px;
  padding: 2px 4px;
}
.barCode_left_2 {
}
.barcode_image {
  height: 23rem;
  position: absolute;
  right: 0;
}
.barcode_contianer {
  position: relative;
  top: -15rem;
}
.hiringPara2 {
  font-family: Poppins;
  font-size: 13px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 1px;
  padding-bottom: 10px;
  margin: 10px 0; /* Add space below each paragraph */
  display: block;
}
.btn-img {
  width: 135px;
  height: 35.838px;
  flex-shrink: 0;
  padding: 0 8px;
}
.test3 {
  display: flex;
  justify-content: center;
}
.barcode_image {
  height: 376px;
  position: absolute;
  right: 0;
}

@media (max-width: 1295px) {
  .barcode_image {
    height: 315px;
    position: absolute;
    right: -110px;
  }
  .hiringPara {
    font-size: 14px;
  }
  .barcode_man {
    width: 100%;
    height: 260px;
    position: relative;
    top: -48px;
  }
  .hiringPara2 {
    font-size: 11px;
  }
  .test2 {
    padding-right: 26px;
  }
}
@media (max-width: 1080px) {
  .test2 {
    padding-top: 1px;
  }
}
@media (max-width: 928px) {
  .hiringPara {
    width: 100%;
    font-size: 18px;
    text-align: center;
  }
  .subHeading {
    font-size: 19px;
  }
  .were-hiring {
    padding-left: 2rem;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left-barcode {
    width: 40%;
  }
  .right-barcode {
    width: 60%;
    padding-right: 10px;
  }
  .barcode_man {
    width: 100%;
    height: 220px;
    position: relative;
    top: -48px;
    position: relative;
    margin-bottom: -3rem;
  }
  .barcode_image {
    width: 38%;
    height: auto;
    position: absolute;
    left: 0;
  }
  .barcode {
    z-index: 2;
    position: relative;
    width: auto;
    top: 128px;
    left: 66px;
  }
  .div2-barcode {
    display: flex;
    position: relative;
    margin-bottom: -3rem;
  }
  .pink-camera,
  .frame {
    height: 90px;
  }
  .subHeading {
    font-size: 26px;
    text-align: center;
    padding-bottom: 20px;
  }
  .barcode_container {
    height: 500px;
    width: auto;
  }
  .barcodeimg {
  }
}

@media (max-width: 850px) {
  .barcode_image {
    width: 36%;
    height: auto;
    position: absolute;
  }
  .barcode {
    position: relative;
    width: 36%;
    height: auto;
  }
}
@media (max-width: 750px) {
  .hiringPara {
    font-size: 16px;
  }
  .subHeading {
    font-size: 19px;
  }
  .were-hiring {
    padding-left: 1rem;
    padding-top: 2rem;
  }
  .barcode {
    z-index: 2;
    position: relative;
    width: 34%;
    top: 100px;
    left: 60px;
  }
  .barcode_container {
    height: 26.25rem;
  }
}
@media (max-width: 660px) {
  .barcode_image {
    width: 40%;
    height: auto;
    position: absolute;
  }
  .barcode {
    position: relative;
    width: 36%;
    height: auto;
    top: 110px;
    left: 60px;
    right: 50px;
  }
  .hiringPara {
    font-size: 13px;
  }
  .subHeading {
    font-size: 17px;
  }
  .were-hiring {
    padding-top: 2rem;
  }
}
@media (max-width: 550px) {
  .hiringPara {
    font-size: 12px;
  }
  .barcode {
    z-index: 2;
    position: relative;
    width: 45%;
    top: 80px;
    left: 42px;
  }
  .barcode_image {
    width: 50%;
    height: auto;
    position: absolute;
  }
}
@media (max-width: 450px) {
  .airoplan img {
    width: 50px;
  }
  .hiringPara {
    font-size: 10px;
  }
  .were-hiring {
    padding-left: 1rem;
    padding-top: 2rem;
  }
  .left-barcode {
    width: 40%;
  }
  .right-barcode {
    width: 60%;
    padding-right: 10px;
  }
  .barcode_man {
    width: 100%;
    height: 150px;
    position: relative;
    top: -32px;
  }
  .barcode_image {
    width: 55%;
    height: auto;
    position: absolute;
    left: 0;
  }
  .barcode {
    z-index: 2;
    position: relative;
    width: 45%;
    top: 100px;
    left: 60px;
  }
  .div2-barcode {
    display: flex;
  }
  .pink-camera,
  .frame {
    height: 100px;
  }
  .subHeading {
    font-size: 16px;
    text-align: center;
  }
  .barcode_container {
    margin-top: 70px;
    height: 520px;
    width: auto;
  }
  .barcodeimg {
  }
}
