.feed-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    background-color: #f5f5f5;
    /* Light background for the whole page */
}

.post {
    width: 100%;
    max-width: 600px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
    /* Prevent content overflow */
}

.post-header {
    display: flex;
    align-items: center;
    padding: 16px;
}

.person-img2 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    /* Maintain aspect ratio */
}

.user-info {
    flex-grow: 1;
}

.user-name {
    font-weight: 600;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.location {
    display: flex;
    align-items: center;
}

.location-text {
    margin-left: 4px;
    font-size: 14px;
    color: #555;
}

.post-content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    overflow-y: auto;
    min-height: 500px;
}

.post-description {
    font-size: 16px;
    margin: 16px 0;
}

.community-img {
    width: 100%;
    height: auto;
    border-radius: 0 0 12px 12px;
    object-fit: cover;
    /* Maintain aspect ratio */
}

.place-img {
    height: 100%;
    width: auto;
    border-radius: 0 0 12px 12px;
    object-fit: contain;
    /* Maintain aspect ratio */
}

.post-footer {
    padding: 16px;
    border-top: 1px solid #e0e0e0;
}

.like-section {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 0;
}

.like-section svg {
    margin-right: 8px;
}

.comments-section {
    margin-top: 16px;
}

.comment-container {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.person-img3 {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
    /* Maintain aspect ratio */
}

.comment-details {
    display: flex;
    flex-direction: column;
}

.comment-name {
    font-weight: bold;
}

.comment-text {
    color: #555;
}

.no-comment {
    text-align: center;
    color: #888;
    font-size: 14px;
}

/* Media Queries */
@media (max-width: 768px) {
    .post {
        margin: 0 auto;
        border-radius: 0;
    }

    .post-header,
    .post-content,
    .post-footer {
        padding: 12px;
    }

    .post-description,
    .user-name,
    .location-text {
        margin: 0 8px;
    }

    .like-section {
        padding: 8px 0;
    }
}

.community-comment {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    background-color: #f5f5f5;
}

.community-img {
    width: 100%;
    max-width: 600px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
}

.post-header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 2px solid #cecece;
}

.person-img2 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.user-info {
    flex-grow: 1;
}

.user-name {
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 18px;
}

.places-box2 {
    display: flex;
    align-items: center;
}

.placeText {
    margin-left: 8px;
    font-size: 14px;
    color: #555;
}

.post-content {
    padding: 16px;
}

.post-description {
    font-size: 16px;
    margin-bottom: 16px;
}

.activities-section {
    margin-top: 16px;
}

.activities-section h3 {
    font-size: 18px;
    margin-bottom: 8px;
}

.activities-section ul {
    list-style-type: none;
    padding: 0;
}

.activities-section ul li {
    font-size: 14px;
    color: #555;
    margin-bottom: 4px;
}

.post-footer {
    padding: 16px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
}

.view-count {
    font-size: 14px;
    color: #555;
}

.like-section {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.like-section svg {
    margin-right: 8px;
}

/* Media Queries */
@media (max-width: 768px) {
    .community-img {
        border-radius: 0;
        margin: 0 auto;
    }

    .post-header,
    .post-content,
    .post-footer {
        padding: 12px;
    }

    .person-img2 {
        width: 30px;
        height: 30px;
    }

    .post-description,
    .user-name,
    .placeText {
        font-size: 14px;
    }

    .like-section {
        font-size: 12px;
    }
}

.activities-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.chip {
    display: inline-block;
    padding: 8px 12px;
    margin: 4px;
    background-color: #f0f0f0;
    border-radius: 16px;
    font-size: 14px;
    color: #333;
    border: 1px solid #ddd;
}

.chip:hover {
    background-color: #e0e0e0;
    border-color: #ccc;
}