/* .card_back_main_div {
  display: flex;
  width: auto;
  padding: 15px;
  height: auto;
  background-color: yellow;
  flex: 1;
}
.cart_back_images1 {
  height: 300px;
  padding-top: 150px;
  width: 10%;
}
.cart_back_images2 {
  height: 700px;
  width: 10%;
  padding-top: 600px;
}
.card_navbar_back {
  flex: 1;
  width: 80%;
}
.arrow_image {
  height: 15px;
  width: 20px;
}
.view_all {
  font-size: 15px;
  font-weight: bold;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  flex: 1;
  margin-right: 65px;
  height: 375px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.card_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  margin-bottom: 30px;
}
.card_back {
  display: flex;
  height: 50vh;
  max-width: 300px;
}
cart_view_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card_div_row1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 40px;
  justify-content: space-evenly;
}

.card_div_row2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 20px;
  justify-content: space-evenly;
}
.card_heading {
  font-size: 25px;
  font-weight: 500;
  margin-right: 150px;
}
.menu {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.home_menu {
  display: none;
}

.card_image {
  height: 120px;
  border-radius: 8px;
  width: 162px;
}
.rating_div {
  position: absolute;
  top: 300px;
  left: 278px;
  height: 40px;
  width: 230px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  padding-top: -30px;
  background-color: rgb(245, 245, 245);
  box-shadow: 2px 2px 5px 0px rgba(212, 205, 205, 0.2);
}
.rating_div span {
  font-size: 13px;
  font-weight: 400;
}
.admin_image {
  height: 15px;
  width: 13px;
  padding-right: 8px;
}
.card_text {
  border-bottom: 1px solid black;
  height: 90px;
}
.card_text p {
  padding-top: 30px;
  font-size: 15px;
  font-weight: 600;
}
.card_text span {
  font-size: 14px;
  font-weight: 300;
}
.bottom_card {
  display: flex;
  align-self: center;
  margin-top: 10px;
}
.card_button_left {
  height: 30px;
  width: 40%;
}
.card_button_right {
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  width: 30%;
  margin-right: -20px;
}
.card_button_right img {
  margin-top: 35px;
  height: 10px;
  width: 20px;
}
.div_with_icon {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 90px;
  margin-right: 5px;
  border-radius: 5px;
  background-color: #a7dbf5;
}
.cross_icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  font-size: 16px;
}
.div_with_icon button {
  font-size: 9px;
  font-weight: 400;
  width: 60px;
  border: transparent;
  background-color: #a7dbf5;
}
.card_explore span {
  font-size: 12px;
  font-weight: 500;
} */

/* @media (max-width: 767px) {
  .cart_back_images1,
  .cart_back_images2,
  .card_max_width2,
  .menu {
    display: none;
  }
  .home_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-left: 30px;
  }
  .menu_back_image {
    background-size: 100% 100%;
    width: 25%;
    margin: auto;
    padding-bottom: 3px;
    padding-left: 20px;
  }
  .view_all {
    margin-right: -50px;
  }

  .card_heading {
    margin: 20px;
    text-align: right;
    font-size: 17px;
    padding-right: 35px;
    font-weight: 600;
  }
  .card_items {
    display: inline;
    align-items: center;
    justify-content: center;
  }

  .card_max_width {
    overflow: scroll;
    width: 450px;
    height: 320px;
  }
  .card {
    margin-right: 20px;
    height: 300px;
    width: 200px;
    border-radius: 6px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .card_image {
    height: 120px;
    border-radius: 8px;
    width: 162px;
  }
  .rating_div {
    position: absolute;
    top: 375px;
    left: 55px;
    height: 30px;
    width: 140px;
    margin: auto;
  }
  .rating_div span {
    font-size: 12px;
    font-weight: 400;
  }
  .admin_image {
    height: 13px;
    width: 10px;
    padding-right: 8px;
  }
  .ratting {
    font-size: 11px;
    font-weight: 400;
  }
  .card_text {
    border-bottom: 1px solid black;
    height: 55px;
    margin-bottom: 20px;
  }
  .card_text p {
    padding-top: 10px;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .card_text span {
    font-size: 14px;
    font-weight: 300;
  }
  .bottom_card {
    display: flex;
    align-self: center;
    justify-content: space-between;
  }
  .card_button_left {
    width: 40%;
    background-color: red;
  }
  .card_button_right {
    font-size: 11px;
    font-weight: 400;
    height: 40px;
    width: 30%;
  }
  .card_button_right img {
    margin-top: 35px;
    height: 10px;
    width: 20px;
  }
  .div_with_icon {
    margin-top: 8px;
    height: 22px;
    width: 50px;
  }
  .cross_icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    font-size: 16px;
  }
  .div_with_icon button {
    font-size: 8px;
    font-weight: 500;
    width: 60px;
    height: 10px;
    border: transparent;
    background-color: #a7dbf5;
  }
  .card_explore span {
    font-size: 10px;
    font-weight: 400;
  }
} */
.places-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #ccc; */
  padding: 10px 0;
}

.options {
  display: flex;
  gap: 30px; /* Adjust the gap as needed */
}

.options span {
  color: #262626;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.viewAll {
  padding-left: 18px;
  font-weight: bold;
  font-family: Poppins;
  font-size: 14px;
}
.img-container {
  padding: 1% 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}
.imageBox2 {
  height: 360px;
  /* width: fit-content; */
}
.fit-image2 {
  width: 100%; /* Make sure the image does not exceed the container width */
  height: 100%; /* Make sure the image does not exceed the container height */
  display: block; /* Remove any extra spacing or margin around the image */
  margin: 0 auto;
  border-radius: 4%;
}
.img-container2 {
  padding: 10px 20px;
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px; */
}
.visitor-text {
  color: #a9a9a9;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.box-name {
  color: #262626;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.textbox {
  padding: 0 30px;
}
.places-box {
  display: flex;
  border-bottom: 1px solid #9b9b9b;
  padding: 6px 0 8px;
}

.cart-para {
  font-size: 14px;
  border-bottom: 1px solid #9b9b9b;
  padding: 6px 0 8px;
}
.placeText {
  padding: 0 6px;
  color: #a9a9a9;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.explore {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  left: 194px;
  bottom: 25px;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.cartBox2 {
  width: 320px;
  height: 80px; /* Set the initial height */
  overflow: hidden;
  border-radius: 0px 10px 10px 0px;
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  /* transition: right 0.3s; */
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
}
.absolute {
  position: relative;
}
/* .card-container {
  position: absolute;
} */
.cartBox2.expanded {
  height: 360px; /* Set the expanded height */
  position: absolute;
  transform: rotateY(180deg) scaleX(-1);
}
@media (max-width: 450px) {
  .places-heading {
    flex-direction: column;
  }
}
