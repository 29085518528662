.stay_file_uploader {
  width: 130px;
  height: 130px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
}
.stay_photos_uploader {
  width: 130px;
  height: 130px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
}
.photos_uploader {
  position: relative;
}
.delete_icon {
  position: absolute;
  top: 8px;
  right: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  /* font-size: 18px; */
}
.stay_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.delete_room_icon_child {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 49px;
  height: 49px;
}
.delete_room_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add_room_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}
.add_room_button {
  align-self: center;
  padding: 10px 30px;
  color: rgb(49, 227, 174);
  font-size: large;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid lightgray;
  border-radius: 10px;
}
.multiImg_container {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 30px 0px;
}
.social_media_container {
  border: 1px solid red;
}
.multiImg_main_container {
  width: 100%;
}
.socialmedia_main_container {
  width: 100%;
  margin-top: 40px;
}
.stay_buton_container {
  display: flex;
  justify-content: end;
  padding: 20px 0px 0px 0px;
}
.stay_buton_container_childs {
  display: flex;
  gap: 20px;
  justify-content: end;
}
.additional_photos_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.shop_gear_product_main {
  border: 1px solid lightgray;
  padding: 0px 20px 20px 20px;
  margin: 17px 0px 0px 17px;
}
.add_product_container {
  background-color: #e4eef2;
  border: 1px solid black;
  border-radius: 10px;
}
.add_room_container {
  background-color: #e4eef2;
  border: 1px solid black;
  border-radius: 10px;
  padding: 0px 0px 20px 0px;
}
.add_icon_container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.product_multiImg_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.product_wrapper {
  display: flex;
  gap: 20px;
  padding: 20px 0px 0px 0px;
}
.product_multiImg_main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.room_input_wrapper {
  margin-top: 20px;
  padding: 0px 0px 15px 0px;
}
.product_menu {
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 10px 0px;
  cursor: pointer;
}
.popover {
  display: flex;
  gap: 10px;
  align-items: center;
}
.geoLocatoin_container {
  padding: 38px 0px 0px 38px;
}
@media (max-width: 767px) {
  .multiImg_container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    padding: 30px 0px 30px 10px;
    flex-wrap: wrap;
  }
  .socialmedia_main_container {
    width: 100%;
    padding: 0px 0px 0px 10px;
    margin-top: 30px;
  }
  .add_room_container {
    background-color: #e4eef2;
    border: 1px solid black;
    border-radius: 10px;
    padding: 0px 0px 20px 0px;
    margin-left: 17px !important;
  }
  .room_input_wrapper {
    margin-top: 20px;
    padding: 0px 0px 15px 0px;
    border-bottom: 1px solid gray;
  }
  .product_wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .product_multiImg_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .product_multiImg_main_container {
    padding-bottom: 20px;
  }
}
