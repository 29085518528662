.person-img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 5px;
  overflow: hidden;
}
.person-img3 {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 10px;
  margin-top: 10px;
  overflow: hidden;
}
.person-img2 {
  height: 100%;
  width: 100%;
}
.community-img {
  max-width: 100%;
  max-height: 605px;
}
.community-img-div {
  max-width: 50%;
  max-height: 480px;
  margin: 5px;
  overflow: hidden;
}
.no-comment {
  text-align: center;
}
.like-section {
  padding-left: 10px;
  padding-top: 6px;
  display: flex;
  border-top: 2px solid #cecece;
}
.comment-container {
  display: -webkit-box;
}
.community-comment {
  width: 50%;
  max-height: 458px;
  border: 2px solid #cecece;
  padding: 10px;
  padding-left: 20px;
  margin: 5px;
  border-radius: 5px;
}
.places-box2 {
  display: flex;
  /* padding: 6px 0 8px; */
}
.below {
  padding-top: 20px;
  height: 320px;
  overflow-y: auto;
}
.each-section {
  padding: 10px 0;
}
.person-name {
  color: #3e3e3e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 75% */
  letter-spacing: -0.165px;
}
.user-name {
  color: #2fe3ad;
  font-family: Poppins;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.person-desc {
  color: #5f5f5f;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.placeText2 {
  padding: 0 2px;
  color: #a9a9a9;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
