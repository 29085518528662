/* .mobile_main_container_with_icon {
  display: flex;
  max-width: auto;
  margin: auto;
  height: auto;
  align-items: center;
  justify-content: center;
}

.mobile_text button {
  margin-top: 50px;
}
.mobile_main_container {
  flex: 1;
  display: flex;
  margin: auto;
  max-width: 80%;
  height: auto;
  padding: 40px;
  align-items: center;
  justify-content: center;
}

.mobile_images_div {
  max-width: auto;
}

.mobile_image1 p,
.mobile_images_div p {
  font-size: 25px;
  font-weight: 600;
}

.mobile_images_div2 {
  max-width: auto;
}

.mob1_img {
  padding-bottom: 70px;
  max-height: 500px;
  max-width: 170px;
}
.mob2_img {
  margin-left: -30px;
  max-height: 500px;
  max-width: 200px;
}

.mobile_text {
  flex: 1;
  height: auto;
  max-width: 50%;
}

.bullet-list-item {
  display: flex;
}

.bullet-icon {
  margin-right: 5px;
  font-size: 20px;
  color: black;
  line-height: 1;
}

.mobile_side_icon {
  height: auto;
}
.mobile_side_icon img {
  object-fit: contain;
  flex: 1;
  width: auto;
  max-height: 80px;
  margin-bottom: 500px;
}

.mobile_text p {
  font-size: 25px;
  font-weight: 600;
}
.mobile_text li {
  flex: 1;
  max-width: auto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
} */

/* ============================================================== */
/* 
@media (max-width: 992px) {
  .mobile_main_container {
    display: inline;
  }
  .bullet-icon {
    display: none !important;
  }
  .mobile_images_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: auto;
    height: auto;
  }

  .mobile_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    text-align: center;
    max-width: 90%;
    height: auto;
  }
  .mobile_image1 p {
    font-size: 25px;
    font-weight: 600;
    margin-top: 20px;
    margin-left: 80px;
  }
  .mobile_text li {
    text-align: center;
    max-width: 90%;
  }

  .mob3_img {
    padding-bottom: 100px;
    max-height: 650px;
    max-width: 190px;
  }
  .mob4_img {
    margin-left: -5px;
    max-height: 470px;
    max-width: 240px;
  }
}

@media (max-width: 767px) {
  .mobile_main_container_with_icon {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: auto;
    background-color: ye;
    padding: 20px;
    align-items: center;
    justify-content: space-around;
  }
  .mobile_main_container_with_icon > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: auto;
    margin: auto;

    height: auto;
  }

  .mobile_text {
    height: auto;  
  }

  .mobile_images_div {
    max-width: auto;
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .mobile_text li {
    max-width: auto; 
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .mobile_main_container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile_image1 {
    align-items: center;
    display: inline;
  }

  .mobile_image1 p {
    font-size: 21px;
    font-weight: 600;
    max-width: auto;
    display: flex;
    flex-direction: column;
  }

  .mob1_img {
    max-height: 300px;
    max-width: 350px;
  }
  .mob2_img {
    margin-left: -10px;
    max-height: 300px;
    max-width: 250px;
  }

  .mobile_side_icon img {
    height: 150px;
  }

  .bullet-icon {
    margin-right: 5px;
    font-size: 20px;
    color: black;
    display: none;
    line-height: 1;
  }
} */
.img-box1 {
  display: flex;
  padding-bottom: 0;
}
.sub-container1 {
  padding: 0 8%;
}
.twoMobile {
  width: 40%;
  display: flex;
}
.twoMobileText {
  width: 60%;
  padding-top: 70px;
  padding-left: 50px;
}
.twoMobileText1 {
  width: 50%;
  padding-top: 70px;
  /* padding-left: 50px; */
}

.mob1_img {
  width: 100%; /* Set width to 100% for responsiveness */
  max-width: 13rem; /* Set a maximum width */
  max-height: 35.93rem;
  position: relative;
  top: -36px;
}

.mob2_img {
  width: 100%; /* Set width to 100% for responsiveness */
  max-width: 240px; /* Set a maximum width */
  max-height: 575px; /* Allow the height to adjust automatically based on the aspect ratio */
  flex-shrink: 0;
  position: relative;
  top: 48px;
  left: -58px;
}
.mob3_img {
  width: 100%; /* Set width to 100% for responsiveness */
  max-width: 215px; /* Set a maximum width */
  max-height: 505px;
  position: relative;
  top: -46px;
}

.mob4_img {
  width: 100%; /* Set width to 100% for responsiveness */
  max-width: 210px; /* Set a maximum width */
  max-height: 505px; /* Allow the height to adjust automatically based on the aspect ratio */
  flex-shrink: 0;
  position: relative;
  top: 8px;
  left: -44px;
}

.bullet-icon {
  margin-right: 5px;
  font-size: 20px;
  color: black;
  line-height: 1;
}
.bullet-list-item {
  display: flex;
}

@media (max-width: 1250px) {
  .sub-container1 {
    padding: 0%;
  }
}
@media (max-width: 1150px) {
  .mob1_img {
    max-width: 12rem; /* Set a maximum width */
    max-height: 30.93rem;
  }

  .mob2_img {
    max-width: 210px;
    max-height: 505px;
    position: relative;
    left: -44px;
  }
}
@media (max-width: 929px) {
  .img-box1 {
    flex-direction: column;
  }
  .mob1_img {
    width: 140px;
    top: -16px;
  }

  .mob2_img {
    width: 150px;
    position: relative;
    left: -35px;
  }
  .mob3_img {
    width: 150px;
    top: -90px;
    left: 20px;
  }

  .mob4_img {
    width: 150px;
    position: relative;
    top: -40px;
    left: -5px;
  }
  .twoMobile {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .twoMobileText {
    width: 100%;
    padding-left: 0px;
    text-align: center;
    position: relative;
    top: -80px;
  }
  .twoMobileText1 {
    width: 100%;
    height: 110px;
    padding-left: 0px;
    text-align: center;
    position: relative;
    top: -140px;
    padding-bottom: 0;
  }
  .bullet-icon {
    display: none;
  }
}
/* @media (max-width: 450px) {
  .img-box1 {
    flex-direction: column;
  }
  .mob1_img {
    top: -16px;
  }

  .mob2_img {
    width: 150px;
    position: relative;
    left: -5px;
  }
  .mob3_img {
    width: 150px;
    top: -90px;
    left: 20px;
  }

  .mob4_img {
    width: 150px;
    position: relative;
    top: -40px;
    left: -5px;
  }
  .twoMobileText {
    width: 110%;
    padding-left: 0px;
    text-align: center;
    position: relative;
    top: -80px;
  }
  .twoMobileText1 {
    width: 110%;
    height: 110px;
    padding-left: 0px;
    text-align: center;
    position: relative;
    top: -140px;
    padding-bottom: 0;
  }
  .bullet-icon {
    display: none;
  }
} */
