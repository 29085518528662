.option-main {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}
.option-second {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.option-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
