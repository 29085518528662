.container1 {
  padding: 1% 12%;
  max-height: 1300px;
  position: relative;
  top: -12em;
}
.container2 {
  padding: 1% 12%;
}
.balun1 {
  height: 10vw;
  position: absolute;
  top: 160px;
  left: 0;
}
.balun2 {
  height: 8vw;
  position: absolute;
  top: 50px;
  right: 0;
}
.balun3 {
  height: 7vw;
  position: absolute;
  bottom: 250px;
  right: 35px;
  transform: rotate(60deg);
}
.side-roll {
  height: 6vw;
  position: absolute;
  bottom: 550px;
  left: 0;
}
.subParaHeading {
  padding: 0% 4%;
  padding-top: 10px;
  /* padding-top: -4px; */
}
.lilpadding,
.para {
  padding: 1.5% 3%;
}

.subHeading1 {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.5px; /* 75.714% */

  letter-spacing: 1.8px;
}
.t-n-s-header {
  position: absolute;
  top: -20px;
  padding-left: 10vw;
  z-index: 9;
  font-size: 2.2vw;
  font-weight: 700;
  font-family: poppins;
  top: 5vw;
  color: #fff;
}
.privacy-image {
  height: auto;
}

.image-container {
  position: relative;
}

.privacy-policy-heading {
  position: absolute;
  top: 50%;
  left: 20%;
  font-size: 2.2vw;
  font-weight: 700;
  transform: translate(-50%, -50%);
  color: #fff;
}

/* .privacy-policy-heading {
  position: absolute;
  padding-left: 10vw;
  top: 130px;
  z-index: 9;
  font-size: 2.2vw;
  font-weight: 700;
  font-family: poppins;
  color: #768;
} */
@media (max-width: 1128px) {
  .t-n-s-header {
    top: 80px;
  }
}
@media (max-width: 984px) {
  .subHeading1 {
    font-size: 20px;
  }
  .container1 {
    padding: 3% 10%;
  }
}
@media (max-width: 754px) {
  .container1 {
    padding: 5% 10%;
  }
  .t-n-s-header {
    top: 60px;
  }
  .para {
    font-size: 13px;
  }
  .subHeading1 {
    font-size: 18px;
  }
}
@media (max-width: 654px) {
  .container1 {
    padding: 9% 10%;
    margin-bottom: 100px;
  }
  .t-n-s-header {
    top: 50px;
  }
}
@media (max-width: 554px) {
  .container1 {
    padding: 13% 8%;
    margin-bottom: 150px;
  }
  .t-n-s-header {
    top: 40px;
  }
}
@media (max-width: 494px) {
  .container1 {
    padding: 16% 7%;
    margin-bottom: 180px;
  }
  .t-n-s-header {
    top: 40px;
  }
  .subHeading1 {
    font-size: 16px;
  }
}
@media (max-width: 440px) {
  .container1 {
    padding: 20% 6%;
    margin-bottom: 200px;
  }
}
@media (max-width: 418px) {
  .para {
    font-size: 11px;
  }
  .container1 {
    margin-bottom: 300px;
  }
}
