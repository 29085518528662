.navbar_back {
  height: 70px;
  width: 100%;
}
.navbar_items {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navbar_items {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.menu {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.menu li {
  font-size: 34px;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 20px;
}

.menu li:hover {
  background-image: url("../Images/home_back.png");
}

.menu li.active {
  background-image: url("../Images/home_back.png");
  background-color: rgba(249, 248, 248, 0.3);
  background-size: cover;
  background-repeat: no-repeat;
}

.navbar_icons i {
  font-size: 24px;
}
.menu a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the text color from the parent element */
}

.menu a:hover {
  /* Define styles for when the anchor (link) is hovered */
  text-decoration: none; /* Remove underline on hover */
  color: inherit; /* Inherit the text color on hover */
}

.menu a:visited {
  /* Define styles for when the anchor (link) is visited */
  text-decoration: none; /* Remove underline for visited links */
  color: inherit; /* Inherit the text color for visited links */
}

/* .menu_back_image {
  background-image: url(../Images/home_back.png);
  background-size: 100% 100%;
}
.mobile_back_image {
  background-image: url(../Images/home_back.png);
  background-size: 100% 100%;
  height: 20px;
  width: 80px;
} */
.menu_back_image li {
  color: white;
}

.mob_menu {
  position: absolute;
  right: 0;
  top: 0;
  height: 250px;
  width: 200px;
  background-color: white;
  display: flex;
  align-items: right;
  flex-direction: column;
  line-height: 20px;
  margin: auto;
  list-style: none;
  gap: 5px;
  flex: 1;
  justify-content: right;
}

.mob_menu_back_image {
  border-bottom: 1px solid #31e3ae;
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mob_menu_back_image img {
  height: 30px;
  width: 30px;
  padding-right: 50px;
}
.mob_menu_back_image label {
  font-size: 15px;
  font-weight: 600;
}
.mob_menu button {
  height: 25px;
  border-radius: 7px;
  width: 70px;
}

.mob_menu li {
  text-align: center;
  border-bottom: 1px solid #31e3ae;
  /* padding-bottom: 15px; */
}
.hemBurger {
  padding-top: 20px;
  margin-right: 50px;
}
/* ////////////// */
.menu li {
  list-style: none;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
}
.logo_img {
  height: 50px;
  width: 190px;
  margin-left: 50px;
  padding-top: 10px;
}
.navbar_icons i {
  color: rgb(0, 187, 255);
  padding: 0 5px;
}
@media (max-width: 767px) {
  .navbar_icons {
    display: none;
  }
  .menu {
    display: none !important;
  }
  .navbar_items {
    display: none;
  }
  .navbar_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo_img {
    flex: 1;
    height: 30px;
    max-width: 90px;
    padding-top: 10px;
  }
}
@media (min-width: 767px) {
  .hemBurger {
    display: none;
  }
}
