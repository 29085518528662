.faq_container {
  max-width: 80%;
  margin: auto;
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 50px;
  position: relative;
}

.faq_left_div {
  width: 50%;
  top: 50px;
  position: relative;
}

.faq_right_div {
  width: 50%;
  padding-left: 40px;
  position: relative;
}

.pop_up_text {
  border-radius: 0 0 8px 8px;
  background: #1d7d60;
  color: hsl(0, 0%, 100%);
  max-width: 550px;
  z-index: 4;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
.pop_up_text.open {
  max-width: 536px;
  padding: 5px 8px;
  max-height: 1000px; /* Adjust this value according to your content size */
}
.faq_back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  max-width: 550px;
  background: #fff;
  box-shadow: 1.78794px 1.78794px 8.93971px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.faq_back1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  max-width: 550px;
  background: #fff;
  box-shadow: 1.78794px 1.78794px 8.93971px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 10px 0;
}

.faq_back p,
.faq_back1 p {
  font-size: 14px;
  font-weight: 500;
  padding-left: 15px;
}

.faq_back img,
.faq_back1 img {
  width: 35px;
  padding-right: 35px;
}
.faq_right_div {
  margin-top: 111px;
}
.faq_left_div h4 {
  font-size: 24px;
}
.faq_container2 {
  max-width: 80%;
  margin: auto;
  display: flex;
  align-self: center;
  justify-content: space-around;
}
.rotate_mobile_left {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50%;
}
.rotate_mobile_left img {
  max-width: 500px;
}
.rotate_mobile_right {
  padding-top: 100px;
  max-width: 50%;
}
.rotate_mobile_right h4 {
  font-size: 28px;
  font-weight: 400;
  padding-bottom: 15px;
}
.rotate_mobile_right p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}
.rotate_mobile_right span {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
input[type="radio"] {
  height: 20px;
  width: 20px;
  margin: 0 10px;
}
.rotate_mobile_right input[type="email"] {
  height: 40px;
  width: 280px;
  border-radius: 8px;
  border: 1px solid rgb(132, 125, 125);
  padding-left: 15px;
}
::placeholder {
  color: rgb(174, 171, 171);
}
.rotate_mobile_right button {
  height: 40px;
  width: 150px;
  border-radius: 8px;
  border: transparent;
  margin-left: 20px;
  font-size: 13px;
  font-weight: 500;
  color: white;
  background-color: #31e3ae;
}
.dis_b {
  display: none;
}
@media (max-width: 1275px) {
  .faq_left_div h4 {
    font-size: 22px;
    font-weight: 600;
  }
  .faq_left_div p {
    font-size: 14px;
    font-weight: 500;
  }
  .faq_right_div p {
    font-size: 14px;
    font-weight: 500;
  }
  .pop_up_text {
    font-size: 14px;
  }
}
@media (max-width: 1375px) {
  .faq_left_div h4 {
    font-size: 25px;
    font-weight: 600;
  }

  .faq_left_div p {
    font-size: 14px;
    font-weight: 500;
  }
  .pop_up_text {
    font-size: 11px;
    padding-top: 0px;
  }
}
@media (max-width: 1155px) {
  .faq_left_div h4 {
    font-size: 21px;
    font-weight: 600;
  }
  .faq_left_div p {
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 10px;
  }
  .pop_up_text {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .faq_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .faq_right_div {
    margin-top: 50px;
    max-width: 100%;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
  }
  .faq_left_div {
    margin-top: 20px;
    max-width: 100%;
    display: flex;

    flex-direction: column;
    justify-content: center;
  }
  .faq_back {
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faq_left_div h4 {
    padding-top: 10px;
    font-size: 26px;
    font-weight: 600;
  }
  .faq_left_div p {
    /* padding-bottom: 20px;
    padding-top: 15px; */
    /* margin-left: 10px; */
    font-size: 14px;

    font-weight: 500;
  }
  .faq_back img {
    height: 35px;
    padding-right: 20px;
  }
  .faq_back p {
    font-size: 14px;
    font-weight: 500;
    padding-left: 15px;
  }
  .faq_container2 {
    display: inline;
  }
  .rotate_mobile_left {
    max-width: 100%;
    display: flex;
  }
  .rotate_mobile_right {
    padding-top: 10px;
    max-width: 100%;
    display: inline;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .rotate_mobile_right button {
    /* margin-top: 20px; */
    font-weight: 500;
    color: white;
    background-color: #31e3ae;
    margin-bottom: 20px;
  }
}
@media (max-width: 650px) {
  .flex {
    width: 100%;
    display: inline-grid;
    justify-items: center;
    align-items: baseline;
  }
  .faq_container {
    display: inline;
  }
  .faq_back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    max-width: 400px;
    margin-top: 20px;
  }
  .faq_back img {
    height: 28px;
    padding-right: 5px;
  }
  .faq_right_div {
    max-width: 57%;
    margin: auto;
    padding-top: 50px;
    flex-direction: column;
    justify-content: center;
  }
  .faq_left_div {
    max-width: 57%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .faq_left_div h4 {
    margin-left: 5px;
    padding-top: 5px;
    font-size: 18px;
    font-weight: 600;
  }
  .faq_left_div p,
  .pop_up_text {
    font-size: 10px;
    font-weight: 500;
  }

  .faq_right_div p,
  .rotate_mobile_right p {
    font-size: 10px;
    font-weight: 500;
  }
  .dis_n {
    display: none;
  }

  .rotate_mobile_left img {
    max-width: 400px;
  }

  .dis_b {
    display: block;
    font-size: 22px;
    font-weight: 400;
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  .rotate_mobile_right input[type="email"] {
    height: 35px;
    width: 200px;
    border-radius: 5px;
    border: 1px solid rgb(132, 125, 125);
    padding-left: 10px;
  }

  input[type="radio"] {
    height: 15px;
    width: 15px;
    margin: 0 10px;
  }
  .rotate_mobile_right button {
    height: 30px;
    width: 100px;
    border-radius: 5px;
    margin-left: 0px;
    margin-top: 5px;
    font-size: 9px;
    font-weight: 500;
  }
  .rotate_mobile_right span {
  }
}
@media (max-width: 450px) {
  .faq_container {
    display: inline;
  }
  .faq_back,
  .faq_back1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    max-width: 300px;
    margin-top: 10px;
  }
  .pop_up_text {
    max-width: 700px;
    height: 50px;
    /* position: absolute; */
  }
  .faq_back img,
  .faq_back1 img {
    height: 24px;
    width: auto;
    padding-right: 10px;
  }
  .faq_back p,
  .faq_back1 p {
    padding-left: 10px;
  }
  .faq_right_div {
    max-width: 90%;
    margin: auto;
    margin-top: 50px;
    padding-top: 0;
    flex-direction: column;
    justify-content: center;
  }
  .faq_left_div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .faq_left_div h4 {
    margin-left: 5px;
    padding-top: 5px;
    font-size: 16px;
    font-weight: 600;
  }
  .faq_left_div p,
  .pop_up_text {
    font-size: 9px;
    font-weight: 500;
    padding-top: 0px;
  }
  .faq_right_div p,
  .rotate_mobile_right p {
    font-size: 9px;
    font-weight: 500;
  }
  .dis_n {
    display: none;
  }

  .rotate_mobile_left img {
    max-width: 400px;
  }

  .dis_b {
    display: block;
    font-size: 22px;
    font-weight: 400;
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  .rotate_mobile_right input[type="email"] {
    height: 35px;
    width: 200px;
    border-radius: 5px;
    border: 1px solid rgb(132, 125, 125);
    padding-left: 10px;
  }

  input[type="radio"] {
    height: 15px;
    width: 15px;
    margin: 0 10px;
  }
  .rotate_mobile_right button {
    height: 30px;
    width: 100px;
    border-radius: 5px;
    margin-left: 0px;
    font-size: 9px;
    font-weight: 500;
  }
}
