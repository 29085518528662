.mobile_second_component {
  display: none;
}

.second_component {
  display: flex;
  flex-direction: column;
  background-image: url(../Images/background1.png);
  background-size: 100% 100%;
  height: 80vh;
}
.background_text {
  display: flex;
  margin-left: 220px;
  width: 50%;
  margin-top: 200px;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
}
.background_text h6 {
  color: #253d65;
  font-size: 22px;
  font-weight: 700;
}
.background_text h3 {
  color: #253d65;
  font-size: 35px;
  font-weight: 700;
  padding-bottom: 10px;
}
.background_text p {
  color: black;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  padding-right: 50px;
  padding-bottom: 20px;
}
.background_text button {
  height: 40px;
  width: 170px;
  border-radius: 7px;
  border: transparent;
  color: #06c8f7;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .background_text {
    margin-top: 150px;
    margin-left: 180px;
  }
}

@media (max-width: 992px) {
  .second_component {
    height: auto;
  }
  .background_text {
    margin-left: 150px;
    margin-top: 110px;
  }
  .background_text button {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .second_component {
    display: none;
  }
  .mobile_second_component {
    display: block;
    background-image: url(../Images/mob_back_home.jpeg);
    background-size: 100% 100%;
    object-fit: contain;
    height: auto;
  }
  .mobile_background_text {
    text-align: center;
  }
  .mobile_background_text h6 {
    padding-top: 30px;
    color: black;
    font-size: 23px;
    font-weight: 700;
  }
  .mobile_background_text p {
    padding: 10px 20px;
    font-size: 13px;
    font-weight: 500;
  }
  .mobile_background_text button {
    height: 40px;
    width: 135px;
    border-radius: 7px;
    border: transparent;
    color: #06c8f7;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 35px;
  }
  .mobile_background_text h3 {
    padding-top: 20px;
    color: #253d65;
    font-size: 18px;
    font-weight: 700;
  }
}
