.middleAndSubcribe_wrapper {
  display: flex;
  justify-content: space-between;
}

.footer_back_color {
  display: flex;
  align-items: center;
  justify-content: s;
  max-height: auto;
  max-width: auto;
  background-color: #253d65;
}
.footer_width {
  color: white;
  max-width: 80%;
  margin: auto;
  padding-top: 25px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left,
.middle,
.right {
  max-width: 33%;
}
.footer_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: auto;
  padding: 20px;
  height: auto;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
}
/* .middle_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */
.footer_div h3 {
  font-size: 20px;
  font-weight: 600;
}
.footer_div p {
  /* margin-top: 20px; */
  font-size: 12px;
  font-weight: 500;
}
.middle_text ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.middle {
  display: flex;
  justify-content: space-evenly;
}

.middle_text ul li {
  margin: 5px 0;
}

.footer_div input {
  line-height: 35px;
  border-radius: 8px;
  max-width: 500px;
  padding-left: 10px;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
  margin-top: 20px;
  color: #fcfcfc;
}
::placeholder {
  color: white;
}

.footer_div button {
  line-height: 25px;
  border-radius: 8px;
  width: 120px;
  margin-top: 10px;
  color: #31e3ae;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid white;
  outline: none;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
}

li {
  list-style: none;
  font-size: 13px;
  font-weight: 400;
  padding-top: 5px;
}

.left img {
  margin-bottom: 10px;
}
.left p {
  margin-bottom: 10px;
  padding-right: 50px;
  font-size: 13px;
  font-weight: 400;
}
.middle {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}

.head_menu {
  width: 110px;
  font-size: 17px;
  font-weight: 600;
}
.become_partner_title {
  width: 200px;
  font-size: 17px;
  font-weight: 600;
}
.title_p {
  border-bottom: 1px solid white;
  width: fit-content;
}
.children {
  cursor: pointer;
}
.stay_guide_link {
  color: #ffffff;
  text-decoration: none;
}
.whats_icon {
  margin-top: 2px;
  margin-right: 5px;
  height: 13px;
}
label {
  font-size: 12px;
  font-weight: 400;
}
.copy_right_footer a,
.center2 a,
.center1 a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the text color from the parent element */
}

.copy_right_footer a:hover,
.center2 a:hover,
.center1 a:hover {
  /* Define styles for when the anchor (link) is hovered */
  text-decoration: none; /* Remove underline on hover */
  color: inherit; /* Inherit the text color on hover */
}

.copy_right_footer a:visited,
.center2 a:visited,
.center1 a:visited {
  /* Define styles for when the anchor (link) is visited */
  text-decoration: none; /* Remove underline for visited links */
  color: inherit; /* Inherit the text color for visited links */
}
.copy_right_footer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 94%;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.copy_right_footer p {
  font-size: 12px;
  font-weight: 400;
}
.hr_line {
  margin-top: 20px;
  height: 1px;
  background: linear-gradient(
    270deg,
    rgba(49, 227, 174, 0) 0%,
    #31e3ae 50.52%,
    rgba(49, 227, 174, 0) 100%
  );
}
.center1 {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 13px;
  font-weight: 400;
}
.center2 {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: -25px;
  font-size: 13px;
  font-weight: 400;
}
.about_us_section {
  padding-left: 14px;
}
.become_partner_section {
  padding-left: 14px;
}
@media (max-width: 930px) {
  .left img {
    height: 25px;
  }

  .left img {
    height: 25px;
  }
  .head_menu {
    /* width: 120px; */
  }
}
/* @media (min-width: 769px) {
  .whatsapp-link {
    display: none;
  }
} */

@media (max-width: 767px) {
  .footer {
    display: inline;
    align-items: center;
    justify-content: space-between;
  }
  .footer_back_color {
    max-height: auto;
  }
  .footer_width {
    color: white;
    max-width: 80%;
    margin: auto;
    padding-top: 25px;
  }
  .left,
  .middle,
  .right {
    max-width: 100%;
  }
  .copy_right_footer {
    text-align: center;
    display: inline;
    height: 50px;
    background-color: red;
  }
  .copy_right_footer p {
    margin-top: 10px;
  }
  .center2 {
    margin-bottom: 15px;
  }
  .middle {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 20px;
  }
  .about_us_section {
    padding-left: 0px;
  }
  .become_partner_section {
    padding-left: 0px;
  }
}

@media (max-width: 1680px) {
  .footer {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .left {
    max-width: 50%;
  }
}

@media (max-width: 940px) {
  .footer {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .left {
    max-width: 100%;
  }
  .middleAndSubcribe_wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .right {
    max-width: 100%;
  }
  .middle_text {
    max-width: 100%;
  }
}
